import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ContentfulService } from 'src/app/services/contentful.service';
import { SideNavService } from './side-nav.service';
import { MatSidenav } from '@angular/material/sidenav'; 

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0, 0, 0)',
          opacity: 1,
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)',
          opacity: 0,
        })
      ),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('1300ms ease-in-out')),
    ]),
  ],
})
export class SideNavComponent implements OnInit, OnDestroy {

  @ViewChild('drawer', { static: false }) drawer!: MatSidenav;
  
  nav_position: string = 'end';
  logo: string;
  opened: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay()
  );
  drugdata: any;
  versionone_med: any;
  versionone_medname: any;
  result2: any;
  resid: any;
  resourceres: any;
  landingpageid: any;
  private subscriptions: Subscription[] = [];
  private initialLoadComplete = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private contentfulservice: ContentfulService,
    private sidebarService: SideNavService
  ) {}

  ngOnInit(): void {
    this.getHizentraimage();
    this.getMenuDetails();
    this.getMenu2Details();

    let url = window.location.href;
    let landingurlword = url.substring(url.lastIndexOf('/') + 1);
    let wordinurl = url.substring(0, url.lastIndexOf('/'));
    let resourceurl = wordinurl.substring(0, wordinurl.lastIndexOf('/'));
    let wordinurl2 = wordinurl.substring(wordinurl.lastIndexOf('/') + 1);
    let resourceurlword = resourceurl.substring(resourceurl.lastIndexOf('/') + 1);
    let a = wordinurl2;
    let b = resourceurlword;

    if (b === 'resource') {
      this.subscriptions.push(
        this.contentfulservice.getVersiononeMedicines().subscribe(res => {
          this.drugdata = res;
          this.versionone_med = res.results.map(item => item.name);
          this.versionone_medname = filterItems(this.versionone_med, wordinurl2);
          this.result2 = this.drugdata.results.find(item => item.name === this.versionone_medname[0]);
          this.resid = this.result2.contentfulResourceId;
          this.subscriptions.push(
            this.contentfulservice.getdatawithoutId(this.resid).subscribe(res => {
              this.resourceres = res;
              let nav_id = res.fields.navigation.sys.id;
              this.subscriptions.push(
                this.contentfulservice.getdatawithoutId(nav_id).subscribe(res => {
                  let headerstate = res.fields.internalName;
                  if (headerstate == 'QSA Universal Navigation') {
                    let a = res.fields.imageWrapper.sys.id;
                    this.subscriptions.push(
                      this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                        let logolink = res.fields.url;
                        document.getElementById('logimg').setAttribute('href', logolink);
                        document.getElementById('logimg').style.cursor = 'pointer';
                      })
                    );
                    this.showhamburgermenu();
                  } else if (headerstate == 'QSA Universal Navigation - NO HAMBURGER') {
                    let a = res.fields.imageWrapper.sys.id;
                    this.subscriptions.push(
                      this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                        document.getElementById('logimg').setAttribute('alt', res.fields.altText);
                      })
                    );
                    this.hidehamburgermenu();
                  } else {
                    this.showhamburgermenu();
                  }
                })
              );
            })
          );
        })
      );
    } else {
      if (a == 'home') {
        this.subscriptions.push(
          this.contentfulservice.getVersiononeMedicines().subscribe(res => {
            this.drugdata = res;
            this.versionone_med = res.results.map(item => item.name);
            this.versionone_medname = filterItems(this.versionone_med, landingurlword);
            this.result2 = this.drugdata.results.find(item => item.name === this.versionone_medname[0]);
            this.landingpageid = this.result2.contentfulLandingPageId;
            this.subscriptions.push(
              this.contentfulservice.getdatawithoutId(this.landingpageid).subscribe(res => {
                let nav_id = res.fields.navigation.sys.id;
                this.subscriptions.push(
                  this.contentfulservice.getdatawithoutId(nav_id).subscribe(res => {
                    let headerstate = res.fields.internalName;
                    if (headerstate == 'QSA Universal Navigation') {
                      let a = res.fields.imageWrapper.sys.id;
                      this.subscriptions.push(
                        this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                          let loglink = res.fields.url;
                          document.getElementById('logimg').setAttribute('href', loglink);
                          document.getElementById('logimg').style.cursor = 'pointer';
                          document.getElementById('logoimgalt').setAttribute('alt', res.fields.altText);
                        })
                      );
                      this.showhamburgermenu();
                    } else if (headerstate == 'QSA Universal Navigation - NO HAMBURGER') {
                      let a = res.fields.imageWrapper.sys.id;
                      this.subscriptions.push(
                        this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                          document.getElementById('logoimgalt').setAttribute('alt', res.fields.altText);
                        })
                      );
                      this.hidehamburgermenu();
                    } else {
                      this.showhamburgermenu();
                    }
                  })
                );
              })
            );
          })
        );
      } else {
        if (a == 'landing-page-preview' || a == 'resource-page-preview') {
          let lastWord = url.substring(url.lastIndexOf('/') + 1);
          console.log(lastWord);
          this.subscriptions.push(
            this.contentfulservice.getdatawithoutId(lastWord).subscribe(res => {
              this.resourceres = res;
              let nav_id = res.fields.navigation.sys.id;
              this.subscriptions.push(
                this.contentfulservice.getdatawithoutId(nav_id).subscribe(res => {
                  let headerstate = res.fields.internalName;
                  if (headerstate == 'QSA Universal Navigation') {
                    let a = res.fields.imageWrapper.sys.id;
                    this.subscriptions.push(
                      this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                        let logolink = res.fields.url;
                        document.getElementById('logimg').setAttribute('href', logolink);
                        document.getElementById('logimg').style.cursor = 'pointer';
                      })
                    );
                    this.showhamburgermenu();
                  } else if (headerstate == 'QSA Universal Navigation - NO HAMBURGER') {
                    let a = res.fields.imageWrapper.sys.id;
                    this.subscriptions.push(
                      this.contentfulservice.getdatawithoutId(a).subscribe(res => {
                        document.getElementById('logimg').setAttribute('alt', res.fields.altText);
                      })
                    );
                    this.hidehamburgermenu();
                  } else {
                    this.showhamburgermenu();
                  }
                })
              );
            })
          );
        } else {
          this.showhamburgermenu();
        }
      }
    }

    this.subscriptions.push(
      this.isHandset$.subscribe(isHandset => {
        if (!this.initialLoadComplete) {
          //this.toggleMenu();
          this.toggleMenuAndDrawer
          this.initialLoadComplete = true;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit() {
    if (window.location.href.endsWith('pdfViewer')) {
      document.getElementById('element-to-hide').style.display = 'none';
    }
  }

  hidehamburgermenu() {
    document.getElementById('sidenavButton').style.display = 'none';
    document.getElementById('logimg').removeAttribute('href');
    document.getElementById('logimg').style.cursor = 'default';
    document.getElementById('logoimgalt').setAttribute('alt', 'QSA');
  }

  showhamburgermenu() {
    document.getElementById('sidenavButton').style.display = 'block';
    document.getElementById('logimg').setAttribute('href', 'http://qsasupport.com');
    document.getElementById('logimg').style.cursor = 'pointer';
    document.getElementById('logoimgalt').setAttribute('alt', 'QSA');
  }

  name = 'Angular';
  menuState: string = 'out';

  // toggleMenu() {
  //   this.menuState = this.menuState === 'in' ? 'out' : 'in';
  //   this.sidebarService.subject.next(this.menuState === 'in');
  // }

  toggleMenuAndDrawer() {
    this.menuState = this.menuState === 'in' ? 'out' : 'in';
    this.sidebarService.subject.next(this.menuState === 'in');
    this.drawer.toggle();
  }

  getHizentraimage() {
    this.contentfulservice
      .getAssets('/2OaXuPw13P57gNYXtfBQP8/')
      .subscribe(
        imgData => (this.logo = imgData.fields.file.url),
        err => console.log(err)
      );
  }

  new = '';
  newLink = '';
  getMenuDetails() {
    this.contentfulservice.getdata('4BRqcClhx9uviPxboGVv6X').subscribe(res => {
      this.new = res.fields.navigationText;
      this.newLink = res.fields.navigationLink;
    });
  }

  about = '';
  aboutLink = '';

  getMenu2Details() {
    this.contentfulservice.getdata('23v636PtpJtUz4GGmZwA2a').subscribe(res => {
      this.about = res.fields.navigationText;
      this.aboutLink = res.fields.navigationLink;
    });
  }
}

function filterItems(arr, query) {
  return arr.filter(element => element.toLowerCase() === query.toLowerCase());
}
