import { Component, HostListener, OnInit } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Observable } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

@Component({
  selector: 'app-landing-preview-page-subresource',
  templateUrl: './landing-preview-page-subresource.component.html',
  styleUrls: ['./landing-preview-page-subresource.component.scss']
})
export class LandingPreviewPageSubresourceComponent implements OnInit {


  responseData: any[] | undefined = [];
  subResourceContent = [];
  resourcespaceID: number;
  isi_id: any;
  resid: any;
  resid2: any;
  resourceprimarycolor: any;
  resourcetertiaryColor: any;
  resourcesecondarycolor: any;
  resourcefontColor: any;
  drugdata: any;
  versionone_med: any;
  qsaUrl: string;
  versionone_medname: any;
  result2: any;
  drug: any;
  landingpageid: any;
  resourcehorizontalRule: any;
  indication_header: any;
  isi_header: any;
  indication_text: any;
  isi_text: any;



  constructor(private contentfulservice: ContentfulService) {

    let str = window.location.href;
    this.qsaUrl = str;
    let wordinurl = (this.qsaUrl.substring(this.qsaUrl.lastIndexOf('/') + 1));
    console.log(wordinurl)
    this.landingpageid = wordinurl;
    this.getshortstickIsiwithoutid(wordinurl);
    this.getColor();

    // this.contentfulservice.getVersiononeMedicines().subscribe(res => {
    //   this.drugdata = res;
    //   this.versionone_med = res.results.map(item => {
    //     return item.contentfulLandingPageId;
    //   });

    //   let str = window.location.href;
    //   this.qsaUrl = str;
    //   let urlmatch = (str.substring(str.lastIndexOf('/') + 1));
    //   this.versionone_medname = (filterItems(this.versionone_med, urlmatch))
    //   this.result2 = this.drugdata.results.find(item => item.contentfulLandingPageId === this.versionone_medname[0]);
    //   //this.drug = this.result2.name;
    //   this.resid = this.result2.contentfulResourceId;
    //   this.contentfulservice.resid_Visibility(this.resid)
    //   this.landingpageid = this.result2.contentfulLandingPageId;
    //   console.log(this.resid, this.landingpageid)


    //   this.getshortstickIsiwithoutid(this.landingpageid);
    //   this.getColor();
    // })

    // function filterItems(arr, query) {
    //   //return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
    //   return arr.filter(element => element.toLowerCase() === query.toLowerCase());
    // }

  }

  offsetFlag = true;
  mainText: Observable<[]>;
  heading: Observable<[]>;
  paragraph: Observable<[]>;
  unorderedList: Observable<[]>;
  listItem: Observable<[]>;
  listItem1: Observable<[]>;
  subhead: Observable<[]>;
  mainTexts
  shortStickyIsi: any;

  ngOnInit() { }



  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({
      behavior: "smooth"
    });
  }

  private getElementById(id: string): HTMLElement {
    //console.log("element id : ", id);
    const element = document.getElementById(id);
    return element;
  }

  scrollToElementById(id: string) {
    const element = this.getElementById(id);
    this.scrollToElement(element);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const numb = window.scrollY;
    if (numb >= 1200) {
      this.offsetFlag = false;
    } else {
      this.offsetFlag = true;
    }
  }


  // getshortstickIsiwithoutid(value) {
  //   this.contentfulservice.getdatapreview(value).subscribe(res => {
  //     let a = res.fields.sponsoredHero.sys.id;
  //     this.contentfulservice.getdatapreview(a).subscribe(res => {
  //       let b = res.fields.stickyIsi.sys.id
  //       this.contentfulservice.getdatapreview(b).subscribe(res => {
  //         this.shortStickyIsi = res.fields.shortStickyIsi
  //       });
  //     });
  //   })
  // }
  getshortstickIsiwithoutid(value) {
    this.contentfulservice.getdatawithoutId(value).subscribe(res => {
      let a = res.fields.sponsoredHero.sys.id;
      this.contentfulservice.getdata(a).subscribe(res => {
        let b = res.fields.stickyIsi.sys.id
        this.contentfulservice.getdata(b).subscribe(res => {
          //this.shortStickyIsi = res.fields.shortStickyIsi

          if (res.fields.hasOwnProperty('shortStickyIsiIndicationsHeader')){this.indication_header = res.fields.shortStickyIsiIndicationsHeader;}
          if (res.fields.hasOwnProperty('shortStickyIsiSafetyInformationHeader')){this.isi_header = res.fields.shortStickyIsiSafetyInformationHeader;}
          if (res.fields.hasOwnProperty('shortStickyIsiIndicationsText')){this.indication_text = res.fields.shortStickyIsiIndicationsText;}
          if (res.fields.hasOwnProperty('shortStickyIsiImportantSafetyInformationText')){this.isi_text = res.fields.shortStickyIsiImportantSafetyInformationText}
        });
      });
    })
  }

  public options: any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Loading...</p>';
    }
    return documentToHtmlString(richText, this.options);
  }



  getColor() {

    //console.log("works!")
    this.contentfulservice.getdatapreview(this.landingpageid).subscribe(res => {
      let brandresources = res.fields.sponsoredHero.sys.id;
      this.contentfulservice.getdatapreview(brandresources).subscribe(res => {
        if (res.fields.hasOwnProperty("branding")) {
          let branding = res.fields.branding.sys.id;
          this.contentfulservice.getdatapreview(branding).subscribe(res => {
            if (res.fields.hasOwnProperty("primaryColor")) { this.resourceprimarycolor = res.fields.primaryColor.value; } else { console.log("resourceprimarycolor not exists") }
            if (res.fields.hasOwnProperty("tertiaryColor")) { this.resourcetertiaryColor = res.fields.tertiaryColor.value; } else { console.log("tertiaryColor not exists") }
            if (res.fields.hasOwnProperty("secondaryColor")) { this.resourcesecondarycolor = res.fields.secondaryColor.value; } else { console.log("resourcesecondarycolor not exists") }
            if (res.fields.hasOwnProperty("fontColor")) { this.resourcefontColor = res.fields.fontColor.value } else { console.log("resourcefontColor not exists") }
            if (res.fields.hasOwnProperty("horizontalRule")) { this.resourcehorizontalRule = res.fields.horizontalRule.value } else { console.log("horizontalRule not exists") }

            document.documentElement.style.setProperty('--resourceprimarycolor', this.resourceprimarycolor ? this.resourceprimarycolor : "#3254a2");
            document.documentElement.style.setProperty('--resourcesecondarycolor', this.resourcesecondarycolor ? this.resourcesecondarycolor : "#691c32");
            document.documentElement.style.setProperty('--resourcehorizontalRuleColor', this.resourcehorizontalRule ? this.resourcehorizontalRule : "#3254a2");
          })
        } else { console.log("branding not exists") }
      })
    })

  }
}