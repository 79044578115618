import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Meta, Title } from '@angular/platform-browser';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  seotitle: any;
  seodes: any;
  hidePagesFromSearchEnginesNoindex: any;
  excluedLinksFromSearchRankingsNofollow: any;
  pagekeywords: any;

  constructor(private contentfulservice : ContentfulService,
    private titleService: Title, private metaservice : Meta) { }

  

  ngOnInit() {
    this.getAboutContent();
    
      this.contentfulservice.getdata('2DKRtXnJPZJIf0vqimXbqu').subscribe(res =>{
        this.titleService.setTitle(res.fields.pageTitle)
        let seoid = res.fields.seoMetadata.sys.id
         this.contentfulservice.getdata(seoid).subscribe(res => {
          this.seotitle = res.fields.seoTitle;
          this.seodes = res.fields.seoDescription;
          this.hidePagesFromSearchEnginesNoindex = res.fields.hidePagesFromSearchEnginesNoindex;
          this.excluedLinksFromSearchRankingsNofollow = res.fields.excluedLinksFromSearchRankingsNofollow;
          this.pagekeywords = res.fields.keywords;
          //console.log(this.seotitle, this.seodes, this.hidePagesFromSearchEnginesNoindex, this.excluedLinksFromSearchRankingsNofollow, this.pagekeywords )

          this.metaservice.addTag( { name:'description',content: this.seodes});
          this.metaservice.addTag( { name:'application-name',content: this.seotitle});
          this.metaservice.addTag( { name:'keywords',content: this.pagekeywords});
          this.metaservice.addTag( { name:'noindex',content: this.hidePagesFromSearchEnginesNoindex});
          this.metaservice.addTag( { name:'nofollow',content:  this.excluedLinksFromSearchRankingsNofollow});
    })
      })
  }

  aboutUsContent:any;
  aboutUsHeading:any;
  getAboutContent(){
    this.contentfulservice.getdata('6muAQkXmyWovJ2vhvPuBLe').subscribe(res =>{
      this.aboutUsHeading = res.fields.heading;
      this.aboutUsContent  = res.fields.copyBlock;
    });
  }

  public options:any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
        return '<p>Loading</p>';
    } else {
        return documentToHtmlString(richText, this.options);
    }
}



}

