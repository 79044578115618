import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { BehaviorSubject, from, Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, delayWhen, map, mergeMap, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  httpHeaders: any;
  resourcespaceID: any;

  ressID: any;
  private test: number;

  resourceIDChange: Subject<any> = new Subject<any>();
  previewresourceIDChange: Subject<any> = new Subject<any>();
  p_ressID: any;


  constructor(private http: HttpClient) {
    this.resourceIDChange.subscribe((value) => {
      this.ressID = value;
      console.log(this.ressID)
    });

  }

  resid_Visibility(value) {
    this.resourceIDChange.next(value);
  }

  public setTest(value: any) {
    this.resourcespaceID = value;
    console.log(this.resourcespaceID);
  }

  public getTest() {
    return this.resourcespaceID;
  }

  checkBoxSubject$ = new BehaviorSubject<any>(null);

  CONFIG = {
    contentTypeIds: {
      productId: environment.accessToken
    }
  }

  private client = createClient({
    space: environment.spaceId,
    accessToken: environment.accessToken
  });


  id = 'access_token=Nr129JpVmw1hjQAv-zy1UM8Pk8vahuU228O0bUScylU&limit=500';
  options = {
    params: new HttpParams({
      fromString: this.id,
    })
  }

  previewid = 'access_token=Qv33QTCrNOKtcCHQkjibEc_6zPts0WML3cnV-xfS8SY&limit=500'
  previewoptions = {
    params: new HttpParams({
      fromString: this.previewid
    })
  }

  getdata(sysId: any): Observable<any> {
    return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/' + sysId, this.options)
      .pipe(
        catchError(error => {
          // Handle the error here
          console.log('First API call failed: ', error);

          // Make a second API call
          return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/' + sysId, this.options)
            .pipe(
              catchError(error => {
                // Handle the error here
                console.log('Second API call failed: ', error);
                return throwError('Something went wrong with the second API call.');
              }),
              map(res => res)
            );
        }),
        map(res => res)
      )
  }


  getdatawithoutId(contentfulId: any): Observable<any> {
    return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/' + contentfulId, this.options)
      .pipe(
        catchError(err => this.handleError(err)),
        map(res => res))
  }

  getAssets(sysId: any): Observable<any> {
    return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/assets' + sysId, this.options)
      .pipe(
        catchError(err => this.handleError(err)),
        map(res => res)
      )
  }
  getassets(sysId: any): Observable<any> {
    return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/assets/' + sysId, this.options)
      .pipe(
        catchError(err => this.handleError(err)),
        map(res => res)
      )
  }


  getAlldata(): Observable<any> {
    return this.http.get<any>(environment.baseApiUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/', this.options)
      .pipe(
        catchError(err => this.handleError(err)),
        map(res => res))
  }

  getDetails(): Observable<any> {
    const url = 'https://api.qsasupport.com/v1/resources/request';
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', this.id);
    return this.http.post<any>(url, { params: queryParams });
  }

  getMailInfo(val) {
    return this.http.post(environment.baseMailUrl, val).pipe(
      catchError(err => this.handleError(err)),
      map(res => res))
  }

  getAccessToken(): Observable<any> {
    const body = {
      "TenantID": "d6341e10-3639-4e17-a8cf-769a310903d6",
      "UserID": "0568aae4-d3c2-4ffd-bf1b-b3860cad59be",
      "Secret": "X4KZ@m2TiZ8l"
    }
    return this.http.post('https://api.discountdrugnetwork.com/api/auth/login?groupId=DDN6120', body)
  }

  /*getMedName(): Observable<any>{
    return this.http.get('https://api.discountdrugnetwork.com/api/Drug/Autocomplete?prefixText=xif')
  }*/

  getMedName(Text): Observable<any> {
    return this.http.get('https://api.discountdrugnetwork.com/api/Drug/Autocomplete?prefixText=' + Text)
  }

  getMedData(drugname, zip): Observable<any> {
    const headersList = new HttpHeaders({ 'Ocp-Apim-Subscription-Key': 'a0ad842b478b410db99c50a9f2ae103b' });
    return this.http.get('https://api.discountdrugnetwork.com/api/Drug/FindDrugs?drugName=' + drugname + '&numPharm=25&brandIndicator=G&zip=' + zip, { headers: headersList })
  }

  getMedDatabyid(drugname, zip, shopname): Observable<any> {
    const headersList = new HttpHeaders({ 'Ocp-Apim-Subscription-Key': 'a0ad842b478b410db99c50a9f2ae103b' });
    return this.http.get('https://api.discountdrugnetwork.com/api/Drug/FindDrugs?drugName=' + drugname + '&numPharm=25&brandIndicator=G&zip=' + zip + '&pharmacyName=' + shopname, { headers: headersList })
  }

  getVersiononeMedicines(): Observable<any> {
    return this.http.get('https://api.qsasupport.com/v1/medicines?limit=50')
  }


  getdatapreview(sysId: any): Observable<any> {
    return this.http.get<any>(environment.previewUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/' + sysId, this.previewoptions)
      .pipe(
        retryWhen(errors =>
          errors.pipe(
            delayWhen(() => timer(1000)),
            mergeMap((error, index) => {
              if (index === 4) {
                return throwError(error);
              }
              return of(error);
            }),
            take(5)
          )
        ),
        catchError(err => this.handleError(err)),
        map(res => res)
      );
  }

  // getdatapreviewwithoutId(contentfulId: any): Observable<any> {
  //   return this.http.get<any>(environment.previewUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/' + contentfulId, this.previewoptions)
  //     .pipe(
  //       catchError(err => this.handleError(err)),
  //       map(res => res))
  // }

  getAssetspreview(sysId: any): Observable<any> {
    return this.http.get<any>(environment.previewUrl + '/spaces/' + environment.spaceId + '/environments/master/assets' + sysId, this.previewoptions)
      .pipe(
        catchError(err => this.handleError(err)),
        map(res => res)
      )
  }


  getAlldatapreview(): Observable<any> {
    return this.http.get<any>(environment.previewUrl + '/spaces/' + environment.spaceId + '/environments/master/entries/', this.previewoptions)
      .pipe(
        catchError(err => this.handleError(err)),
        map(res => res))
  }

  private handleError(httpError: HttpErrorResponse) {
    let message: string = '';
    let statuscode = httpError.status;
    if (statuscode == 401) {
      console.log(statuscode);
      //this.authService.logout();
    }
    if (httpError.error instanceof ProgressEvent) {
      console.log('in progrss event')
      message = "Network error";
    } else {
      message = httpError.error.message;
      console.error(
        `Backend returned code ${httpError.status}, ` +
        `body was: ${httpError.error}`);
    }
    return throwError(message);
  }
}



