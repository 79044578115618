import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Entry } from 'contentful';
import { ClipboardService } from 'ngx-clipboard';
import { forkJoin, from, Observable, of, throwError, timer, zip } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MatDialog } from '@angular/material/dialog';
import { RELATIVE_TEXT } from './emailbody';
import { ToastrService } from 'ngx-toastr';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { catchError, concatMap, delay, delayWhen, mergeMap, retryWhen, take, timeout, timeoutWith } from 'rxjs/operators';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as htmlToPdfmake from 'html-to-pdfmake';
import { MergePdfService } from 'src/app/services/merge-pdf.service';

@Component({
    selector: 'app-resource',
    templateUrl: './resource.component.html',
    styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {

    @ViewChild('closebutton', {
        static: false
    }) closebutton;


    hideToggle = false;
    heading: Observable<[]>;
    subhead: Observable<[]>;
    copyRights: Observable<[]>;
    landFootImg: String;
    logo: String;
    dynamicMsg: any;
    paraContent: any;
    checked: boolean = false;
    pdfValues: any = [];
    myemailForm!: FormGroup
    provideForm!: FormGroup
    submitted = false;
    firstList: any;
    firstListUrl: any;
    firstListValue: any;
    firstListUrlValue: any;
    secondListValue: any;
    secondList: any;
    secondListUrl: any;
    secondListValue2: any;
    secondListValue2Url: any;
    secondListValue2Sl: any;
    secondListValue2Tl: any;
    secondListValue2Fl: any;
    secondListValue2TlUrl: any;
    thirdList: any;
    resourceErrorMessage: "Please select any resource"
    reqId: any
    getfile: any
    routeListener
    userId

    panelOpenState = false;
    indication: any;
    isi: any;
    resid: any;
    isi_id: any;
    dynamicmssg_id: any;
    branddiseaseResources_id: any;
    pageTitle_id: any;
    drugdata: any;
    versionone_med: any;
    versionone_medname: any;
    result2: any;
    resid2: any;
    seotitle: any;
    seodes: any;
    hidePagesFromSearchEnginesNoindex: any;
    excluedLinksFromSearchRankingsNofollow: any;
    pagekeywords: any;
    prescribe_id: any;
    presinfo_id: any;
    copyRights_id: any;
    paraContent_id: any;
    dymssg_id: any;
    dynamicMsg2_id: any;
    icon_id: any;
    icon1_id: any;
    icon2_id: any;
    heading_id: any;
    list_id: any;
    list1_id: any;
    list2_id: any;
    list3_id: any;
    brandheader_id: any;
    qsaUrl: string;
    drug: string;
    resourceres: any;
    brandresourceres: any;
    dynamicmssgres: any;
    footerres: any;
    logoimgid: any;
    shortstickyisi: any;
    gradient1: any;
    gradient2: any;
    gradient3: any;
    indication_header: any;
    isi_header: any;
    gradientrotation: void;
    gradientrotation2: any;
    resourceprimarycolor: any;
    resourcesecondarycolor: any;
    resourcetertiaryColor: any;
    footgradient1: any;
    footgradient2: any;
    footgradientrotation2: any;
    resourceh1color: any;
    resourceh2color: any;
    resourceh3color: any;
    resourcehorizontalRule: any;
    resourcebuttonBackgroundColor: any;
    resourcebuttonFontColor: any;
    resourcebuttonBackgroundRollOverColor: any;
    resourcebuttonRolloverFontColor: any;
    resourcebuttonActiveFontColor: any;
    resourcebuttonBackgroundActiveColor: any;
    resourcefontColor: any;
    resourcefontLinkColor: any;
    resourcefontLinkRolloverColor: any;
    resourcefontLink: any;
    resourcefontFamily: any;
    realtedbuttonids: any;
    button1name: any;
    button1link: any;
    button1textcol: any;
    button1backcol: any;
    button2name: any;
    button2link: any;
    button2textcol: any;
    button2backcol: any;
    relatedbuttonarray: any[];
    presinternalname: any;
    p_file: any;
    presinfo2: any;
    p_link: any;
    resourcebrandFooterBackgroundColor: any;
    resourceHeaderColor: any;
    headerIndicationHeaderFontSize: any;
    headerIndicationCopyFontSize: any;
    headerIndicationFontColor: any;
    isiHeadersFontSize: any;
    isiHeadersColors: any;
    ginacontent: any;
    pdfUrls: string[] = [];
    convertedUrls: string[] = [];
    mergedPdfUrl: Promise<string>;
    viewpdfurl: string;
    provideemailbody: any;
    indication_text: any;
    emailcustomLink: string;
    provideemailsubject: any;
    isiTextFontSize: any;
    bodyTextFontSize: any;
    bodyTextFontWeight: any;
    isiTextFontWeight: any;
    bodyTextLineHeight: any;
    isiTextLineHeight: any;
    Textbanneronly: any;
    Textbanneronlyb: any;
    Textbannerref: any;
    Textbannerrefs: any;
    Textmobilebanneronly: any;
    Textmobilebanneronlyb: any;
    Textmobilebannerref: any;
    Textmobilebannerrefs: any;
    bannerbg: any;
    headingFontSize: any;
    referencesFontColor: any;
    referencesFontSize: any;
    textFontColor: any;
    headingFontSizeMobile: any;
    referencesFontSizeMobile: any;
    headingFontColor: any;
    imageBannerMobile: String;
    imageBanner: String;
    ImageBannerheight: any;
    ImageBannerwidth: any;
    imageText: any;
    imageMobile: any;
    txtbannerwidth: string; 
    textbanneronlyb: any;
    headingAlignment: any;
    textmobilebanneronlyb: any;
    headingAlignmentMobile: any;
    referencesAlignment: any;
    referencesAlignmentMobile: any;
    imgtextFontColor: any;

    constructor(private contentfulservice: ContentfulService, private router: Router,
        private clipboardService: ClipboardService, private http: HttpClient,
        private dialogRef: MatDialog, private toast: ToastrService, private route: ActivatedRoute,
        private fb: FormBuilder, private titleService: Title, private metaservice: Meta, private mergeService: MergePdfService) {



        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        this.getallpageapicalls()


    }

    getallpageapicalls() {
        this.contentfulservice.getVersiononeMedicines().subscribe(res => {
            this.drugdata = res;
            this.versionone_med = res.results.map(item => {
                return item.name;
            });

            let url = window.location.href;
            this.qsaUrl = url;
            let wordinurl = (url.substring(0, url.lastIndexOf('/')));
            let wordinurl2 = (wordinurl.substring(wordinurl.lastIndexOf('/') + 1));
            this.drug = wordinurl2;
            this.versionone_medname = (filterItems(this.versionone_med, wordinurl2))
            this.result2 = this.drugdata.results.find(item => item.name === this.versionone_medname[0]);
            //this.resid2 = this.result2.contentfulId;
            //console.log(this.result2)
            if (this.result2 !== undefined) {
                this.resid2 = this.result2.contentfulResourceId;
                document.getElementById('top').style.display = "block"
                document.getElementById('alertmssg').style.display = "none"
            }
            else {
                //alert("No Matching Drug Found")
                document.getElementById('top').style.display = "none"
                document.getElementById('alertmssg').style.display = "block"
            }
            this.resid = this.resid2;
            console.log(this.resid);
            this.contentfulservice.resid_Visibility(this.resid2)


            function filterItems(arr, query) {
                //return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
                return arr.filter(element => element.toLowerCase() === query.toLowerCase());
            }


            //this.getColor();

            /* this.contentfulservice.getdatawithoutId(this.resid).subscribe(res => {
                 this.resourceres = res;
                 this.getSeoData();
                 this.getAllContents();
                 this.getInformation();
                 this.getPrescribe();
                 this.getAllDynamicMssg();
                 this.getbrandresource();
                 //this.getLogoimage();
                 //this.getrelatedbutton();
                 //this.getdrugrelatedbutton();
                 this.getprovideemailbody();
             })*/

            /* const TIMEOUT_DURATION = 5000; // 5 seconds
 
             this.contentfulservice.getdatawithoutId(this.resid).pipe(
                 mergeMap((res) => {
                     this.resourceres = res;
                     const seoData$ = this.getSeoData();
                     const allContents$ = this.getAllContents();
                     const information$ = this.getInformation();
                     const prescribe$ = this.getPrescribe();
                     const dynamicMssg$ = this.getAllDynamicMssg();
                     const brandResource$ = this.getbrandresource();
                     const provideEmailBody$ = this.getprovideemailbody();
                     return forkJoin([seoData$, allContents$, information$, prescribe$, dynamicMssg$, brandResource$, provideEmailBody$]).pipe(
                         timeout(TIMEOUT_DURATION),
                         catchError((err) => {
                             // handle timeout error
                            // console.error('API call timed out:', err);
                             return of(null); // return empty observable to retry the API call
                         }),
                         delay(1000) // delay retry by 1 second
                     );
                 })
             ).subscribe(
                 () => {
                     // code to execute after all API calls have completed successfully
                     console.log("success")
                 },
                 (error) => {
                     // error handling code
                     console.log(error)
                 }
             );*/
            this.contentfulservice.getdatawithoutId(this.resid)
                .pipe(this.retryWithDelay())
                .subscribe(
                    (data) => {
                        console.log(data);
                        this.resourceres = data;
                        this.getSeoData();
                        this.getAllContents();
                        this.getInformation();
                        this.getPrescribe();
                        this.getAllDynamicMssg();
                        this.getbrandresource();
                        //this.getLogoimage();
                        //this.getrelatedbutton();
                        //this.getdrugrelatedbutton();
                        this.getprovideemailbody();
                    },
                    (error) => {
                        console.error(error);
                    }
                );
        })
    }

    // This function will retry the API call up to 3 times with a 2 second delay between each retry.
    retryWithDelay(maxRetry: number = 3, delay: number = 2000): (src: Observable<any>) => Observable<any> {
        console.log("retried")
        return (src: Observable<any>) =>
            src.pipe(
                retryWhen((errors: Observable<any>) =>
                    errors.pipe(
                        mergeMap((error, i) =>
                            i < maxRetry ? timer(delay) : throwError(error)
                        )
                    )
                )
            );
    }



    scrollToElement(element: HTMLElement) {
        element.scrollIntoView({
            behavior: "smooth"
        });
    }

    private __getElementById(id: string): HTMLElement {
        const element = document.getElementById(id);
        return element;
    }

    scrollToElementById(id: string) {
        const element = this.__getElementById(id);
        this.scrollToElement(element);
    }

    ngOnInit() {



        document.getElementById("head-content").scrollIntoView();



        this.myemailForm = this.fb.group({
            fromEmail: ['support@qsasupport.com'],
            toEmail: ['', [Validators.required]],
            subject: ['A resource has been shared with you'],
            body: ['']
        })


        this.routeListener = this.route.params.subscribe(params => {
            this.userId = params['id'];
            // alert(this.userId)
        });


        this.provideForm = new FormGroup({
            email: new FormControl('', [Validators.required]),
            //id: new FormControl(`${this.userId}`, [Validators.required]),
            qsaUrl: new FormControl('Resource Url'),
            drug: new FormControl('Drug Name'),
            sessionId: new FormControl(`${this.userId}`),
            body: new FormControl('Mail Content'),
            fromEmail: new FormControl('support@qsasupport.com'),
            subject: new FormControl('')
        });
    }



    getprovideemailbody() {
        if (this.resourceres.fields.hasOwnProperty("emailContentForSendingPersonalOgeqsa")) {
            let body = this.resourceres.fields.emailContentForSendingPersonalOgeqsa;
            this.provideemailbody = this._returnHtmlFromRichText(body)
            //console.log(body)
            let url = this.qsaUrl
            const newUrls = url.replace('http://localhost:4200/', 'https://qsasupport.com/')
            //console.log(newUrls)

            //this.provideemailbody = this.provideemailbody + "<br>" + "<a href='" + newUrls + "'>QSA</a>"

            //console.log(this.provideemailbody)
            this.emailcustomLink = newUrls; // Your custom link here
            const updatedHtmlContent = this.provideemailbody.replace(/\[URL Link\]/g, `<a href="${this.emailcustomLink}" target="_blank">${this.emailcustomLink}</a>`);
            this.provideemailbody = updatedHtmlContent;
        }
        else {
            console.log("emailContentForSendingPersonalOgeqsa not exsits")
        }
        if (this.resourceres.fields.hasOwnProperty("emailSubjectForSendingPersonalOgeqsa")) {
            let body = this.resourceres.fields.emailSubjectForSendingPersonalOgeqsa;
            this.provideemailsubject = body
        }
    }



    getSeoData() {
        this.pageTitle_id = this.resourceres.sys.id;
        this.contentfulservice.getdata(this.pageTitle_id).subscribe(res => {

            if (res.fields.hasOwnProperty("seoMetadata")) {
                let seoid = res.fields.seoMetadata.sys.id
                this.titleService.setTitle(res.fields.pageTitle)
                this.contentfulservice.getdata(seoid).subscribe(res => {
                    this.seotitle = res.fields.seoTitle;
                    this.seodes = res.fields.seoDescription;
                    this.hidePagesFromSearchEnginesNoindex = res.fields.hidePagesFromSearchEnginesNoindex;
                    this.excluedLinksFromSearchRankingsNofollow = res.fields.excluedLinksFromSearchRankingsNofollow;
                    this.pagekeywords = res.fields.keywords;
                    this.metaservice.addTag({ name: 'description', content: this.seodes });
                    this.metaservice.addTag({ name: 'application-name', content: this.seotitle });
                    this.metaservice.addTag({ name: 'keywords', content: this.pagekeywords });
                    this.metaservice.addTag({ name: 'noindex', content: this.hidePagesFromSearchEnginesNoindex });
                    this.metaservice.addTag({ name: 'nofollow', content: this.excluedLinksFromSearchRankingsNofollow });
                })
            }
        })
    }


    get f() {
        return this.provideForm.controls;
    }


    provideEmail() {
        let url = this.qsaUrl
        const newUrls = url.replace('http://localhost:4200/', 'https://qsasupport.com/')
        //console.log(newUrls)

        let resurl = "<a href='" + newUrls + "'>Resource URL</a>"
        this.submitted = true
        if (!this.provideForm.invalid) {
            console.log(this.provideemailbody)
            //this.provideForm.get('body').setValue(this.provideemailbody);
            if (typeof this.provideemailbody === 'undefined') {
                this.provideemailbody = resurl;
            }
            this.provideForm.get('body').setValue(this.provideemailbody);
            this.provideForm.get('qsaUrl').setValue(this.qsaUrl);
            this.provideForm.get('drug').setValue(this.drug);
            this.provideForm.get('subject').setValue(this.provideemailsubject);
            this.provideForm.value.body = encodeURI(this.provideemailbody);
            console.log(this.provideForm.value)
            let url = 'https://api.qsasupport.com/v1/resources/request';
            //this.http.post(url, user).subscribe(res => res)
            //this.http.post(url, this.provideForm.value).subscribe(res => res)
            //this.toast.success('Email Submitted')
            // this.router.navigateByUrl('/resource/' + `${this.userId}`)
            this.http.post(url, this.provideForm.value).subscribe(res => {
                this.toast.success('Mail Submitted')
                console.log(res);
                //this.closeModal();
            })
        }
        //this.provideForm.get('email').reset();
    }

    getbrandresource() {

        if (this.resourceres.fields.hasOwnProperty("branddiseaseResources")) {
            this.branddiseaseResources_id = this.resourceres.fields.branddiseaseResources.sys.id;
            // console.log("this.branddiseaseResources_id",this.branddiseaseResources_id);
            //hardcode cdn id
            // this.branddiseaseResources_id = '5my6m5iqUGL5vdU3B96kMS'; //text= 5my6m5iqUGL5vdU3B96kMS ,img= 2CvZYag6X9IL6FlBotLFuI , img+text= 6tGoalsSmo6e1xUy9Qslz
            this.contentfulservice.getdata(this.branddiseaseResources_id)
                .pipe(this.retryWithDelay())
                .subscribe(
                    (res) => {
                        console.log("brandresourceresbrandresourceres", res);
                        this.brandresourceres = res;
                        this.getPrescribeFile();
                        this.getCopyright();
                        this.getContent();
                        this.getDynamicmsg();
                        this.getprescribingInformation();
                        this.getPrescribingInformationLink();
                        this.getLogoimage();
                        this.getTextbanner();
                        this.getTextImgbanner();
                        this.getImagebanner();
                        this.getColor();
                        this.getdrugrelatedbutton()
                    },
                    (error) => {
                        console.error(error);
                    }
                );

        }
        else {
            console.log("branddiseaseResources not exsits")
        }
    }

    masterContent: any;
    getAllContents() {
        this.contentfulservice.getAlldata().subscribe(res => {
            this.masterContent = res.items;
            //console.log(this.masterContent)
            this.getResourceContent();
        });
    }



    resourceContents = [];
    getResourceContent() {
        if (this.resourceres.fields.hasOwnProperty("branddiseaseResources")) {
            this.branddiseaseResources_id = this.resourceres.fields.branddiseaseResources.sys.id;
            this.contentfulservice.getdata(this.branddiseaseResources_id).subscribe(res => {
                //alert(this.branddiseaseResources_id)
                res.fields.healthToolsOnOgeqsa.forEach((element) => {
                    let index = this.masterContent.filter(data => data.sys.id == element.sys.id);
                    if (index) {
                        this.resourceContents.push(index[0]);
                        if (res.fields.healthToolsOnOgeqsa.length == this.resourceContents.length) {
                            this.groupResources();
                        }
                    }
                });
            })
        } else { console.log("branddiseaseResources not exsits") }
    }

    resourceData: any;
    groupResources() {
        //console.log(this.resourceContents)
        var filtered = this.resourceContents.filter(function (x) {
            return x !== undefined;
        });
        //console.log(filtered);

        var groups = new Set(filtered.map(item => item.fields.audiences.sys.id));
        this.resourceData = [];
        groups.forEach(g =>
            this.resourceData.push({
                name: g,
                values: filtered.filter(i => i.fields.audiences.sys.id === g)
            }))
        if (groups.size == this.resourceData.length) {
            this.resourceData.forEach(resource => {
                this.getResourceAudianceTitle(resource.name).then((groupName) => {
                    resource.name = groupName;
                });
            });
        }
    }

    getResourceAudianceTitle(id) {
        let that = this;
        return new Promise(function (resolve, reject) {
            that.contentfulservice.getdata(id).subscribe(res => {
                resolve(res.fields.audience);
            }, err => {
                reject(console.log(err));

            });
        });
    }
    selectedResources: any = [];
    onResourceSelect(event) {
        if (event.checked) {
            this.selectedResources.push(event);
        } else {
            this.selectedResources.pop(event);
        }
    }
    emailContent: any;

    generateEmailContent() {
        this.emailContent = null;
        this.emailContent = "<b> Here is a resource that might be of interest. Please open the link to view:</b> <br>"
        let resourceContent = '';
        if (this.selectedResources.length > 0) {
            this.selectedResources.forEach(resource => {
                resourceContent = resourceContent + "<br><b>" + resource.fields.healthResourceTitle + "</b>" + this._returnHtmlFromRichText(resource.fields.teaser) + " " + "<a href='" + resource.fields.healthResourceUrl + "'>" + resource.fields.healthResourceTitle + ".Pdf</a><br>"
            });
            this.openModal();
        } else {
            this.toast.error("Please select any resource")
        }
        this.emailContent = this.emailContent + resourceContent;
        //console.log(this.emailContent)
    }

    /*copySelectedResourceLinks() {
        this.pdfValues = [];
        if (this.selectedResources.length > 0) {
            this.selectedResources.forEach(resource => {
                this.pdfValues.push(resource.fields.healthResourceUrl);


            });

            console.log(this.pdfValues)
            let filteredArr = this.pdfValues.filter(Boolean)
            const modifiedPdfUrls = filteredArr.map(url => {
                if (!url.startsWith('http:') && !url.startsWith('https:')) {
                    return 'https:' + url;
                }
                return url;
            });

            let finalurl = this.getValidPdfUrls(modifiedPdfUrls)
            console.log(finalurl)
            const PDFMerger = require('pdf-merger-js');
            const merger = new PDFMerger();
            (async () => {
                for (const file of finalurl) {
                    await merger.add(file);
                }
                const mergedPdf = await merger.saveAsBlob();
                const url = URL.createObjectURL(mergedPdf);
                  console.log(url);
                  this.viewpdfurl = url;
                 this.clipboardService.copyFromContent(this.viewpdfurl);
                  this.toast.success('Copied to clipboard');
            })();


        } else {
            this.toast.error("Please select any resource")
        }
    }*/

    copySelectedResourceLinks() {
        this.pdfValues = [];
        localStorage.clear();
        this.pdfValues = [];
        if (this.selectedResources.length > 0) {
            this.selectedResources.forEach(resource => {
                this.pdfValues.push(resource.fields.healthResourceUrl);

                this.clipboardService.copyFromContent(this.pdfValues);
                this.toast.success('Copied to clipboard')
            });
        } else {
            this.toast.error("Please select any resource")
        }
    }

    viewPrintSelectedResourcs() {
        localStorage.clear();
        this.pdfValues = [];
        let prescribeInfo = [this.getfile]
        if (this.selectedResources.length > 0) {
            this.selectedResources.forEach((resource, index) => {
                this.pdfValues.push(resource.fields.healthResourcePrintUrl);
                localStorage.setItem('pdfData', JSON.stringify(this.pdfValues));
                localStorage.setItem('prescribe', JSON.stringify(prescribeInfo))


                if (this.selectedResources.length == index + 1) {
                    window.open('/pdfViewer')
                    //window.location.reload();
                }
            });
        } else {
            this.toast.error("Please select any resource")
        }
    }


    mailPdf(event) {
        let index = this.pdfValues.indexOf(event);
        if (index === -1) {
            return this.pdfValues.push(event);
        } else {
            this.pdfValues.splice(index, 1);
        }
    }

    get result() {
        return this.resourceContents.filter(item => item.fields);
    }


    onSubmit() {
        this.myemailForm.value.body = encodeURI(this.emailContent);
        this.contentfulservice.getMailInfo(this.myemailForm.value).subscribe(res => {
            this.toast.success('Mail Sent')
            this.closeModal();
        })

    }

    openModal() {
        document.getElementById("openbutton").click();
    }

    closeModal() {
        this.closebutton.nativeElement.click();
    }

    // getUserFormData(data:any){
    //   this.contentfulservice.getMailInfo(data).subscribe((res)=>{
    //     return res
    //   })
    // }

    public safetyInformation: any;
    public prescribePdf: any;
    getInformation() {
        if (this.resourceres.fields.hasOwnProperty("isi")) {
            this.isi_id = this.resourceres.fields.isi.sys.id;
        } else { console.log("isi not exsits") }
        this.contentfulservice.getdata(this.isi_id).subscribe(res => {
            // if (res.fields.hasOwnProperty("indications")) { this.indication = res.fields.indications; } else { console.log("indications not exsits") }
            if (res.fields.hasOwnProperty('shortStickyIsiIndicationsHeader')) { this.indication_header = res.fields.shortStickyIsiIndicationsHeader; }
            if (res.fields.hasOwnProperty('shortStickyIsiSafetyInformationHeader')) { this.isi_header = res.fields.shortStickyIsiSafetyInformationHeader; }
            if (res.fields.hasOwnProperty('shortStickyIsiIndicationsText')) { this.indication_text = res.fields.shortStickyIsiIndicationsText; }
            if (res.fields.hasOwnProperty("safetyInformation")) { this.isi = res.fields.safetyInformation } else { console.log("safetyInformation not exsits") }
            if (res.fields.hasOwnProperty("shortStickyIsi")) { this.shortstickyisi = res.fields.shortStickyIsi } else { console.log("shortStickyIsi not exsits") }
            if (res.fields.hasOwnProperty("prescribingInformationLink")) { this.prescribePdf = res.fields.prescribingInformationLink; } else { console.log("prescribingInformationLink not exsits") }
        })
    }

    public prescribe: any

    getPrescribe() {
        if (this.resourceres.fields.hasOwnProperty("isi")) { this.prescribe_id = this.resourceres.fields.isi.sys.id; } else { console.log("isi not exsits") }
        this.contentfulservice.getdata(this.prescribe_id).subscribe(res => {
            if (res.fields.hasOwnProperty("safetyInformation")) { this.prescribe = res.fields.safetyInformation; } else { console.log("safetyInformation not exsits") }
        })
    }




    getPrescribeFile() {
        if (this.brandresourceres.fields.hasOwnProperty("prescribingInformation")) {
            this.presinfo_id = this.brandresourceres.fields.prescribingInformation.sys.id;
            this.contentfulservice.getdata(this.presinfo_id).subscribe(res => {
                if (res.fields.hasOwnProperty("prescribingInformation")) {
                    let presinfo2 = res.fields.prescribingInformation.sys.id;
                    this.contentfulservice.getdata(presinfo2).subscribe(res => {
                        if (res.fields.hasOwnProperty("prescribingInformationPdf")) {
                            let assetsid = res.fields.prescribingInformationPdf.sys.id;
                            this.contentfulservice.getAssets('/' + assetsid + '/').subscribe(res => {
                                this.getfile = res.fields.file.url
                            })
                        }
                        else {
                            if (res.fields.hasOwnProperty('prescribingInformationLink')) {
                                this.getfile = res.fields.prescribingInformationLink
                            }
                            console.log("prescribingInformationPdf not exsits")
                        }
                    })
                }
                else {
                    console.log("prescribingInformation not exsits")
                }
            })
        } else {
            console.log("prescribingInformation not exsits")
        }
    }


    getAllDynamicMssg() {
        if (this.resourceres.fields.hasOwnProperty("dynamicSystemMessages")) {
            this.dymssg_id = this.resourceres.fields.dynamicSystemMessages.sys.id;
            this.contentfulservice.getdata(this.dymssg_id).subscribe(res => {
                this.dynamicmssgres = res;
                //console.log(this.dynamicmssgres)
                this.getIcon();
                this.getIcon1();
                this.getIcon2();
                this.getTopHeading();
                this.getTopDropdown();
                this.getTopListed();
                this.getTopListedDemo();
                this.getTopListed2();
            })
        } else {
            console.log("dynamicSystemMessages not exists")
        }
    }

    linkIcon
    getIcon() {
        this.icon_id = this.dynamicmssgres.fields.messages[2].sys.id;
        this.contentfulservice.getdata(this.icon_id).subscribe(res => {
            let iconid2 = res.fields.image.sys.id;
            this.contentfulservice.getAssets('/' + iconid2 + '/').subscribe(res => {
                this.linkIcon = res.fields.file.url
            })
        })
    }
    copyIcon
    getIcon1() {
        this.icon1_id = this.dynamicmssgres.fields.messages[1].sys.id;
        this.contentfulservice.getdata(this.icon1_id).subscribe(res => {
            let iconid2 = res.fields.image.sys.id;
            this.contentfulservice.getAssets('/' + iconid2 + '/').subscribe(res => {
                this.copyIcon = res.fields.file.url
            })
        })
    }
    searchIcon
    getIcon2() {
        this.icon2_id = this.dynamicmssgres.fields.messages[3].sys.id;
        this.contentfulservice.getdata(this.icon2_id).subscribe(res => {
            let iconid2 = res.fields.image.sys.id;
            this.contentfulservice.getAssets('/' + iconid2 + '/').subscribe(res => {
                this.searchIcon = res.fields.file.url
            })
        })

    }

    getCopyright() {
        if (this.brandresourceres.fields.hasOwnProperty("footer")) {
            this.copyRights_id = this.brandresourceres.fields.footer.sys.id;
            this.contentfulservice.getdata(this.copyRights_id).subscribe(res => {
                this.footerres = res;
                if (res.fields.hasOwnProperty("copyright")) {
                    this.copyRights = res.fields.copyright
                } else { console.log("copyrights not exists") }
                this.getCslimage()
            })
        } else { console.log("footer not exists") }

    }

    getCslimage() {
        this.logoimgid = this.footerres.fields.logoimage[0].sys.id;
        this.contentfulservice.getdata(this.logoimgid).subscribe(res => {
            let bid3 = res.fields.imageWrapper.sys.id;
            this.contentfulservice.getdata(bid3).subscribe(res => {
                let bid4 = res.fields.image.sys.id;
                this.contentfulservice.getAssets('/' + bid4 + '/').subscribe(imgData =>
                    this.landFootImg = imgData.fields.file.url
                );
            })
        })
        /* this.contentfulservice.getAssets('/2aKt26etDN7EJwb2kqn1MI/').subscribe(res => {
             this.landFootImg = res.fields.file.url
         });*/
    }

    public indicationTitle = null;
    getLogoimage() {
        if (this.brandresourceres.fields.hasOwnProperty("branddiseaseHeader")) {
            this.brandheader_id = this.brandresourceres.fields.branddiseaseHeader.sys.id;
            this.contentfulservice.getdata(this.brandheader_id).pipe(
                mergeMap(res => {
                    if (res.fields.hasOwnProperty("logoimage")) {
                        let a = res.fields.logoimage.sys.id;
                        return this.contentfulservice.getdata(a).pipe(
                            mergeMap(res => {
                                if (res.fields.hasOwnProperty("imageWrapper")) {
                                    let b = res.fields.imageWrapper.sys.id;
                                    return this.contentfulservice.getdata(b).pipe(
                                        mergeMap(res => {
                                            if (res.fields.hasOwnProperty("image")) {
                                                let c = res.fields.image.sys.id;
                                                return this.contentfulservice.getAssets('/' + c + '/').pipe(
                                                    mergeMap(imgData => {
                                                        if (imgData.fields.hasOwnProperty("file")) {
                                                            this.logo = imgData.fields.file.url;
                                                            return of(true);
                                                        } else {
                                                            console.log("logo image not exists");
                                                            return of(false);
                                                        }
                                                    }),
                                                    retryWhen(errors =>
                                                        errors.pipe(
                                                            delayWhen(() => timer(1000)),
                                                            mergeMap((error, index) => {
                                                                if (index === 4) {
                                                                    return throwError(error);
                                                                }
                                                                return of(error);
                                                            }),
                                                            take(5)
                                                        )
                                                    )
                                                );
                                            } else {
                                                console.log("image not exists");
                                                return of(false);
                                            }
                                        }),
                                        retryWhen(errors =>
                                            errors.pipe(
                                                delayWhen(() => timer(1000)),
                                                mergeMap((error, index) => {
                                                    if (index === 4) {
                                                        return throwError(error);
                                                    }
                                                    return of(error);
                                                }),
                                                take(5)
                                            )
                                        )
                                    );
                                } else {
                                    console.log("imageWrapper not exists");
                                    return of(false);
                                }
                            }),
                            retryWhen(errors =>
                                errors.pipe(
                                    delayWhen(() => timer(1000)),
                                    mergeMap((error, index) => {
                                        if (index === 4) {
                                            return throwError(error);
                                        }
                                        return of(error);
                                    }),
                                    take(5)
                                )
                            )
                        );
                    } else {
                        console.log("logoimage not exists");
                        return of(false);
                    }
                }),
                retryWhen(errors =>
                    errors.pipe(
                        delayWhen(() => timer(1000)),
                        mergeMap((error, index) => {
                            if (index === 4) {
                                return throwError(error);
                            }
                            return of(error);
                        }),
                        take(5)
                    )
                )
            ).subscribe(res => {
                console.log(res)
                if (res) {
                    if (this.brandresourceres.fields.hasOwnProperty("branddiseaseHeader")) {
                        console.log("this.brandresourceres", this.brandresourceres)
                        let a = this.brandresourceres.fields.branddiseaseHeader.sys.id;
                        this.contentfulservice.getdata(a).subscribe(res => {
                            let b = res.fields.headerIsi.sys.id;
                            this.contentfulservice.getdata(b).subscribe(res => {
                                this.indication = res.fields.copyBlock;
                                this.indicationTitle = res.fields.heading;
                            });
                        });
                    } else {
                        console.log("headerIsi not exists");

                    }
                }
            });
        } else {
            console.log("branddiseaseHeader not exists");
        }
    }

    getDynamicmsg() {
        if (this.brandresourceres.fields.hasOwnProperty("dynamicSystemMessages")) {
            this.dynamicMsg2_id = this.brandresourceres.fields.dynamicSystemMessages.sys.id;
            this.contentfulservice.getdata(this.dynamicMsg2_id).subscribe(res => {
                let dynamicMsg2 = res.fields.messages[0].sys.id
                this.contentfulservice.getdata(dynamicMsg2).subscribe(res => {
                    this.dynamicMsg = res.fields.heading;
                })
            })
        } else { console.log("dynamicSystemMessages not exits") }
    }
    getTextbanner() {
        if (this.brandresourceres.fields.hasOwnProperty("dynamicSystemMessages")) {
            this.dynamicMsg2_id = this.brandresourceres.fields.dynamicSystemMessages.sys.id;
            this.contentfulservice.getdata(this.dynamicMsg2_id).subscribe(res => {
                let dynamicMsg2 = res.fields.messages[0].sys.id
                this.contentfulservice.getdata(dynamicMsg2).subscribe(res => {
                    this.Textbanneronly = res.fields.heading.content[0].content[0].value;
                    this.Textbanneronlyb = res.fields.text.content[0].content[0].value;
                    this.Textbannerref = res.fields.references.content[0].content[0].value;
                    this.Textbannerrefs = res.fields.references.content[0].content[1].value;

                    //mobile
                    this.Textmobilebanneronly = res.fields.headingMobile.content[0].content[0].value;
                    this.Textmobilebanneronlyb = res.fields.headingMobile.content[0].content[1].value;
                    this.Textmobilebannerref = res.fields.referencesMobile.content[0].content[0].value;
                    this.Textmobilebannerrefs = res.fields.referencesMobile.content[0].content[1].value;

                    //banner style content
                    this.headingFontSize = res.fields.headingFontSize;
                    this.referencesFontColor = res.fields.referencesFontColor.value;
                    this.referencesFontSize = res.fields.referencesFontSize;
                    this.bannerbg = res.fields.backgroundColor.value;
                    this.headingFontColor = res.fields.headingFontColor.value;
                    this.imgtextFontColor = res.fields.textFontColor.value;
                    this.txtbannerwidth = res.fields.dyanamicMessagingWidth;
                    this.textbanneronlyb = res.fields.textAlignment;
                    this.headingAlignment = res.fields.headingAlignment;
                    this.textmobilebanneronlyb = res.fields.textAlignmentMobile;
                    this.headingAlignmentMobile = res.fields.headingAlignmentMobile;
                    this.referencesAlignment = res.fields.referencesAlignment;
                    this.referencesAlignmentMobile = res.fields.referencesAlignmentMobile;

                    document.documentElement.style.setProperty('--referencesAlignmentMobile', this.referencesAlignmentMobile);
                    document.documentElement.style.setProperty('--referencesAlignment', this.referencesAlignment);
                    document.documentElement.style.setProperty('--headingAlignment', this.headingAlignment);
                    document.documentElement.style.setProperty('--textmobilebanneronlyb', this.textmobilebanneronlyb);
                    document.documentElement.style.setProperty('--headingAlignmentMobile', this.headingAlignmentMobile);
                    document.documentElement.style.setProperty('--textbanneronlyb', this.textbanneronlyb);
                    document.documentElement.style.setProperty('--txtbannerwidth', this.txtbannerwidth);
                    document.documentElement.style.setProperty('--bannerbg', this.bannerbg);
                    document.documentElement.style.setProperty('--headingFontColor', this.headingFontColor);
                    document.documentElement.style.setProperty('--textFontColor', this.imgtextFontColor);
                    document.documentElement.style.setProperty('--referencesFontColor', this.referencesFontColor);
                    document.documentElement.style.setProperty('--headingFontSize', this.headingFontSize);
                    document.documentElement.style.setProperty('--referencesFontSize', this.referencesFontSize);

                    //mobile
                    this.headingFontSizeMobile = res.fields.headingFontSizeMobile;
                    this.referencesFontSizeMobile = res.fields.referencesFontSizeMobile;

                    document.documentElement.style.setProperty('--headingFontSizeMobile', this.headingFontSizeMobile);
                    document.documentElement.style.setProperty('--referencesFontSizeMobile', this.referencesFontSizeMobile);
                })
            })
        } else { console.log("dynamicSystemMessages not exits") }
    }

    getTextImgbanner() {
        if (this.brandresourceres.fields.hasOwnProperty("dynamicSystemMessages")) {
            this.dynamicMsg2_id = this.brandresourceres.fields.dynamicSystemMessages.sys.id;
            this.contentfulservice.getdata(this.dynamicMsg2_id).subscribe(res => {
                let dynamicMsg2 = res.fields.messages[0].sys.id
                this.contentfulservice.getdata(dynamicMsg2).subscribe(res => {
                    let dynamicMsg3 = res.fields.image.sys.id
                    let dynamicMsg4 = res.fields.imageMobile.sys.id
                    this.contentfulservice.getassets(dynamicMsg3).subscribe(res => {
                        this.imageText = res.fields.file.url
                    })
                    this.contentfulservice.getassets(dynamicMsg4).subscribe(res => {
                        this.imageMobile = res.fields.file.url
                    })
                })
            })
        } else { console.log("dynamicSystemMessages not exits") }
    }

    getImagebanner() {
        if (this.brandresourceres.fields.hasOwnProperty("dynamicSystemMessages")) {
            this.dynamicMsg2_id = this.brandresourceres.fields.dynamicSystemMessages.sys.id;
            console.log("this.dynamicMsg2_id", this.dynamicMsg2_id);
            this.contentfulservice.getdata(this.dynamicMsg2_id).subscribe(res => {
                let dynamicMsg2 = res.fields.messages[0].sys.id
                console.log(dynamicMsg2);
                this.contentfulservice.getdata(dynamicMsg2).subscribe(res => {
                    let dynamicMsg3 = res.fields.imageBanner.sys.id
                    console.log('dynamicMsg3', dynamicMsg3)
                    let dynamicMsg4 = res.fields.imageBannerMobile.sys.id
                    console.log('dynamicMsg4', dynamicMsg4)
                    this.contentfulservice.getassets(dynamicMsg3).subscribe(res => {
                        console.log('res', res)
                        this.imageBanner = res.fields.file.url;
                        this.ImageBannerheight = res.fields.file.details.image.height;
                        this.ImageBannerwidth = res.fields.file.details.image.width;

                        document.documentElement.style.setProperty('--ImageBannerwidth', this.ImageBannerwidth);
                        document.documentElement.style.setProperty('--ImageBannerheight', this.ImageBannerheight);
                        console.log("imageBanner", this.imageBanner);
                    });
                    this.contentfulservice.getassets(dynamicMsg4).subscribe(res => {
                        console.log('res1', res)
                        this.imageBannerMobile = res.fields.file.url;
                        console.log("imageBanner11", this.imageBannerMobile);
                    })
                })
            })
        } else { console.log("dynamicSystemMessages img not exits") }
    }
    getTopHeading() {
        this.heading_id = this.dynamicmssgres.fields.messages[0].sys.id;
        this.contentfulservice.getdata(this.heading_id).subscribe(res => {
            this.heading = res.fields.heading
        })
    }

    getTopDropdown() {
        this.list_id = this.dynamicmssgres.fields.messages[1].sys.id;
        this.contentfulservice.getdata(this.list_id).subscribe(res => {
            if (res.fields.messagecontent.content[0].content[0].value !== undefined) {
                this.firstList = res.fields.messagecontent.content[0].content[0].value
            }
            if (res.fields.messagecontent.content[0].content[1].content[0].value !== undefined) {
                this.firstListUrlValue = res.fields.messagecontent.content[0].content[1].content[0].value
            }
            if (res.fields.messagecontent.content[0].content[1].data.uri !== undefined) {
                this.firstListUrl = res.fields.messagecontent.content[0].content[1].data.uri
            }
            if (res.fields.messagecontent.content[0].content[2].value !== undefined) {
                this.firstListValue = res.fields.messagecontent.content[0].content[2].value
            }
        })
    }

    public getStartedContent: any;
    getTopListed() {
        this.list1_id = this.dynamicmssgres.fields.messages[0].sys.id;
        this.contentfulservice.getdata(this.list1_id).subscribe(res => {
            this.getStartedContent = res.fields.copyBlock;
            this.ginacontent = this.getStartedContent.content[0].content[2].content[0].content[1].value
            //console.log(this.ginacontent)
        })
    }

    getTopListedDemo() {
        this.list2_id = this.dynamicmssgres.fields.messages[2].sys.id;
        this.contentfulservice.getdata(this.list2_id).subscribe(res => {
            if (res.fields.messagecontent.content[0].content[1].content[0].value !== undefined) {
                this.secondList = res.fields.messagecontent.content[0].content[1].content[0].value
            }
            if (res.fields.messagecontent.content[0].content[1].data.uri !== undefined) {
                this.secondListUrl = res.fields.messagecontent.content[0].content[1].data.uri
            }
            if (res.fields.messagecontent.content[0].content[2].value !== undefined) {
                this.secondListValue = res.fields.messagecontent.content[0].content[2].value
            }
            if (res.fields.messagecontent.content[0].content[3].content[0].value !== undefined) {
                this.secondListValue2 = res.fields.messagecontent.content[0].content[3].content[0].value
            }
            if (res.fields.messagecontent.content[0].content[3].data.uri !== undefined) {
                this.secondListValue2Url = res.fields.messagecontent.content[0].content[3].data.uri;
            }
            if (res.fields.messagecontent.content[0].content[4].value !== undefined) {
                this.secondListValue2Sl = res.fields.messagecontent.content[0].content[4].value
            }
            if (res.fields.messagecontent.content[0].content[5].content[0].value !== undefined) {
                this.secondListValue2Tl = res.fields.messagecontent.content[0].content[5].content[0].value
                // alert(this.secondListValue2Tl)
            }
            if (res.fields.messagecontent.content[0].content[5].data.uri !== undefined) {
                this.secondListValue2TlUrl = res.fields.messagecontent.content[0].content[5].data.uri
            }
            if (res.fields.messagecontent.content[0].content[6].value !== undefined) {
                this.secondListValue2Fl = res.fields.messagecontent.content[0].content[6].value
            }
        })
    }

    getTopListed2() {
        this.list3_id = this.dynamicmssgres.fields.messages[3].sys.id;
        this.contentfulservice.getdata(this.list3_id).subscribe(res => {
            this.thirdList = res.fields.messagecontent.content[0].content[0].value
        })
    }

    getContent() {
        if (this.brandresourceres.fields.hasOwnProperty("healthToolTrigger")) {
            this.paraContent_id = this.brandresourceres.fields.healthToolTrigger.sys.id;
            this.contentfulservice.getdata(this.paraContent_id).subscribe(res => {
                if (res.fields.hasOwnProperty("triggerCopy")) {
                    this.paraContent = res.fields.triggerCopy;
                } else { console.log("triggerCopy not exists") }
            })
        } else { console.log("triggerCopy not exists") }
    }

    getprescribingInformation() {
        if (this.brandresourceres.fields.hasOwnProperty('prescribingInformation')) {
            this.presinfo_id = this.brandresourceres.fields.prescribingInformation.sys.id;
            this.contentfulservice.getdata(this.presinfo_id).subscribe(res => {
                if (res.fields.hasOwnProperty('prescribingInformation')) {
                    let p_id = res.fields.prescribingInformation.sys.id
                    this.contentfulservice.getdata(p_id).subscribe(res => {
                        if (res.fields.hasOwnProperty('prescribingInformationPdf')) {
                            let ppdf_id = res.fields.prescribingInformationPdf.sys.id
                            this.presinternalname = res.fields.internalName;
                            this.contentfulservice.getAssets('/' + ppdf_id + '/').subscribe(res => {
                                this.p_file = res.fields.file.url;
                                //console.log(this.presinternalname, this.p_file)
                            })
                        } else {
                            if (res.fields.hasOwnProperty('prescribingInformationLink')) {
                                this.p_file = res.fields.prescribingInformationLink
                            }
                            console.log("prescribingInformationPdf not exists")
                        }
                    })
                } else { console.log("prescribingInformation not exists") }
            })
        } else {
            console.log("prescribingInformation not exists")
        }
    }


    getPrescribingInformationLink() {
        if (this.brandresourceres.fields.hasOwnProperty('prescribingInformation')) {
            this.presinfo_id = this.brandresourceres.fields.prescribingInformation.sys.id;
            this.contentfulservice.getdata(this.presinfo_id).subscribe(res => {
                if (res.fields.hasOwnProperty('prescribingInformation')) {
                    let p_id = res.fields.prescribingInformation.sys.id
                    this.contentfulservice.getdata(p_id).subscribe(res => {
                        if (res.fields.hasOwnProperty('prescribingInformationPdf')) {
                            this.p_link = res.fields.prescribingInformationLink;
                            console.log(this.p_link)
                        } else {
                            console.log("prescribingInformationPdf not exists")
                        }
                    })
                } else { console.log("prescribingInformation not exists") }
            })
        } else {
            console.log("prescribingInformation not exists")
        }
    }

    tabURl = ''
    getTabUrl() {
        this.clipboardService.copyFromContent(window.location.href)
        this.tabURl = window.location.href
        if (this.tabURl != '') {
            this.toast.success('URL Copied to ClipBoard')
        } else {
            this.toast.error('something went wrong')
        }
    }

    public options: any = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
            [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
        }
    }


    _returnHtmlFromRichText(richText) {
        if (richText === undefined || richText === null || richText.nodeType !== 'document') {
            return '<p>Loading</p>';
        } else {
            return documentToHtmlString(richText, this.options);
        }
    }

    /*getrelatedbutton() {
        if (this.resourceres.fields.hasOwnProperty("branddiseaseResources")) {
            this.branddiseaseResources_id = this.resourceres.fields.branddiseaseResources.sys.id;
            this.contentfulservice.getdata(this.branddiseaseResources_id).subscribe(res => {
                if (res.fields.hasOwnProperty("branddiseaseHeader")) {
                    this.brandheader_id = this.brandresourceres.fields.branddiseaseHeader.sys.id;
                    // console.log(this.brandheader_id)
                    this.contentfulservice.getdata(this.brandheader_id).subscribe(res => {
                        if (res.fields.hasOwnProperty("multiDiseaseQsaLinkstabsWrapper")) {
                            let a = res.fields.multiDiseaseQsaLinkstabsWrapper.sys.id;
                            this.contentfulservice.getdata(a).subscribe(res => {
                                this.realtedbuttonids = res.fields.linkstabsbuttons
                                if (res.fields.hasOwnProperty("linkstabsbuttons[0]")) {
                                    let btn1 = res.fields.linkstabsbuttons[0].sys.id
                                    this.contentfulservice.getdata(btn1).subscribe(res => {
                                        if (res.fields.hasOwnProperty("actionbuttonText")) { this.button1name = res.fields.actionbuttonText } else { console.log("actionbuttonText not exists") }
                                        if (res.fields.hasOwnProperty("buttonLink")) { this.button1link = res.fields.buttonLink } else { console.log("buttonLink not exists") }
                                        if (res.fields.hasOwnProperty("customButtonTextColor")) { this.button1textcol = res.fields.customButtonTextColor.value } else { console.log("customButtonTextColor not exists") }
                                        if (res.fields.hasOwnProperty("customButtonBackgroundColor")) { this.button1backcol = res.fields.customButtonBackgroundColor.value } else { console.log("customButtonBackgroundColor not exists") }

                                        document.documentElement.style.setProperty('--btn1bgcolor', this.button1backcol);
                                        document.documentElement.style.setProperty('--btn1txtcolor', this.button1textcol);
                                    })
                                }
                                if (res.fields.hasOwnProperty("linkstabsbuttons[1]")) {
                                    let btn2 = res.fields.linkstabsbuttons[1].sys.id
                                    this.contentfulservice.getdata(btn2).subscribe(res => {
                                        if (res.fields.hasOwnProperty("actionbuttonText")) { this.button2name = res.fields.actionbuttonText } else { console.log("actionbuttonText not exists") }
                                        if (res.fields.hasOwnProperty("buttonLink")) { this.button2link = res.fields.buttonLink } else { console.log("buttonLink not exists") }
                                        if (res.fields.hasOwnProperty("customButtonTextColor")) { this.button2textcol = res.fields.customButtonTextColor.value } else { console.log("customButtonTextColor not exists") }
                                        if (res.fields.hasOwnProperty("customButtonBackgroundColor")) { this.button2backcol = res.fields.customButtonBackgroundColor.value } else { console.log("customButtonBackgroundColor not exists") }

                                        document.documentElement.style.setProperty('--btn2bgcolor', this.button2backcol);
                                        document.documentElement.style.setProperty('--btn2txtcolor', this.button2textcol);
                                    })
                                }
                            })
                        } else { console.log("multiDiseaseQsaLinkstabsWrapper not exists") }
                    })
                } else { console.log("branddiseaseHeader not exists") }
            })
        } else { console.log("branddiseaseResources not exists") }
    }*/

    getColor() {

        //console.log("works!")
        // this.contentfulservice.getdata(this.resid).subscribe(res => {
        //     let brandresources = res.fields.branddiseaseResources.sys.id;
        //     this.contentfulservice.getdata(brandresources).subscribe(res => {
        if (this.brandresourceres.fields.hasOwnProperty("branding")) {
            let branding = this.brandresourceres.fields.branding.sys.id;
            this.contentfulservice.getdata(branding).subscribe(res => {
                if (res.fields.hasOwnProperty("primaryColor")) { this.resourceprimarycolor = res.fields.primaryColor.value; } else { console.log("resourceprimarycolor not exists") }
                if (res.fields.hasOwnProperty("secondaryColor")) { this.resourcesecondarycolor = res.fields.secondaryColor.value;  } else { console.log("resourcesecondarycolor not exists") }
                if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor1")) { this.gradient1 = res.fields.brandHeaderBackgroundGradientColor1.value } else { console.log("brandHeaderBackgroundGradientColor1 not exists") }
                if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor2")) { this.gradient2 = res.fields.brandHeaderBackgroundGradientColor2.value } else { console.log("brandHeaderBackgroundGradientColor2 not exists") }
                if (res.fields.hasOwnProperty("brandHeaderBackgroundGradientColor3")) { this.gradient3 = res.fields.brandHeaderBackgroundGradientColor3.value } else { console.log("brandHeaderBackgroundGradientColor3 not exists") }
                if (res.fields.hasOwnProperty("brandFooterBackgroundGradientColor1")) { this.footgradient1 = res.fields.brandFooterBackgroundGradientColor1.value } else { console.log("brandFooterBackgroundGradientColor1 not exists") }
                if (res.fields.hasOwnProperty("brandFooterBackgroundGradientColor2")) { this.footgradient2 = res.fields.brandFooterBackgroundGradientColor2.value } else { console.log("brandFooterBackgroundGradientColor2 not exists") }
                if (res.fields.hasOwnProperty("fontColor")) { this.resourcefontColor = res.fields.fontColor.value } else { console.log("resourcefontColor not exists") }
                if (res.fields.hasOwnProperty("fontLinkColor")) { this.resourcefontLinkColor = res.fields.fontLinkColor.value } else { console.log("fontLinkColor not exists") }
                if (res.fields.hasOwnProperty("fontLinkRolloverColor")) { this.resourcefontLinkRolloverColor = res.fields.fontLinkRolloverColor.value } else { console.log("fontLinkRolloverColor not exists") }
                if (res.fields.hasOwnProperty("h1Color")) { this.resourceh1color = res.fields.h1Color.value } else { console.log("h1Color not exists") }
                if (res.fields.hasOwnProperty("horizontalRule")) { this.resourcehorizontalRule = res.fields.horizontalRule.value } else { console.log("horizontalRule not exists") }
                if (res.fields.hasOwnProperty("buttonBackgroundRollOverColor")) { this.resourcebuttonBackgroundRollOverColor = res.fields.buttonBackgroundRollOverColor.value } else { console.log("buttonBackgroundRollOverColor not exists") }
                if (res.fields.hasOwnProperty("buttonBackgroundColor")) { this.resourcebuttonBackgroundColor = res.fields.buttonBackgroundColor.value } else { console.log("buttonBackgroundColor not exists") }
                if (res.fields.hasOwnProperty("buttonFontColor")) { this.resourcebuttonFontColor = res.fields.buttonFontColor.value } else { console.log("buttonFontColor not exists") }
                if (res.fields.hasOwnProperty("buttonBackgroundRollOverColor")) { this.resourcebuttonBackgroundRollOverColor = res.fields.buttonBackgroundRollOverColor.value } else { console.log("buttonBackgroundRollOverColor not exists") }
                if (res.fields.hasOwnProperty("buttonRolloverFontColor")) { this.resourcebuttonRolloverFontColor = res.fields.buttonRolloverFontColor.value } else { console.log("buttonRolloverFontColor not exists") }
                if (res.fields.hasOwnProperty("brandFooterBackgroundColor")) { this.resourcebrandFooterBackgroundColor = res.fields.brandFooterBackgroundColor.value } else { console.log("brandFooterBackgroundColor not exists") }
                if (res.fields.hasOwnProperty("resourceHeaderColor")) { this.resourceHeaderColor = res.fields.resourceHeaderColor.value } else { console.log("resourceHeaderColor not exists") }


                if (res.fields.hasOwnProperty("headerIndicationHeaderFontSize")) { this.headerIndicationHeaderFontSize = res.fields.headerIndicationHeaderFontSize } else { console.log("headerIndicationHeaderFontSize not exists") }
                if (res.fields.hasOwnProperty("headerIndicationCopyFontSize")) { this.headerIndicationCopyFontSize = res.fields.headerIndicationCopyFontSize } else { console.log("headerIndicationCopyFontSize not exists") }
                if (res.fields.hasOwnProperty("headerIndicationFontColor")) { this.headerIndicationFontColor = res.fields.headerIndicationFontColor.value } else { console.log("headerIndicationFontColor not exists") }
                if (res.fields.hasOwnProperty("isiHeadersFontSize")) { this.isiHeadersFontSize = res.fields.isiHeadersFontSize } else { console.log("isiHeadersFontSize not exists") }
                if (res.fields.hasOwnProperty("isiHeadersColors")) { this.isiHeadersColors = res.fields.isiHeadersColors.value } else { console.log("isiHeadersColors not exists") }
                if (res.fields.hasOwnProperty("isiTextFontSize")) { this.isiTextFontSize = res.fields.isiTextFontSize } else { console.log("isiTextFontSize not exists") }
                if (res.fields.hasOwnProperty("bodyTextFontSize")) { this.bodyTextFontSize = res.fields.bodyTextFontSize } else { console.log("bodyTextFontSize not exists") }
                //Font Weigth
                if (res.fields.hasOwnProperty("bodyTextFontWeight")) { this.bodyTextFontWeight = res.fields.bodyTextFontWeight } else { console.log("bodyTextFontWeight not exists") }
                if (res.fields.hasOwnProperty("isiTextFontWeight")) { this.isiTextFontWeight = res.fields.isiTextFontWeight } else { console.log("isiTextFontWeight not exists") }

                //Line Height
                if (res.fields.hasOwnProperty("bodyTextLineHeight")) { this.bodyTextLineHeight = res.fields.bodyTextLineHeight } else { console.log("bodyTextLineHeight not exists") }
                if (res.fields.hasOwnProperty("isiTextLineHeight")) { this.isiTextLineHeight = res.fields.isiTextLineHeight } else { console.log("isiTextLineHeight not exists") }
                /*this.resourceh2color = res.fields.h2Color.value
                this.resourceh3color = res.fields.h3Color.value
                this.resourcebuttonBackgroundActiveColor = res.fields.buttonBackgroundActiveColor.value
                this.resourcebuttonActiveFontColor = res.fields.buttonActiveFontColor.value
                this.resourcefontLink = res.fields.fontLink.value
                this.resourcefontFamily = res.fields.fontFamily*/
                document.documentElement.style.setProperty('--resourceprimarycolor', this.resourceprimarycolor ? this.resourceprimarycolor : "#3254a2");
                document.documentElement.style.setProperty('--resourcesecondarycolor', this.resourcesecondarycolor);
                document.documentElement.style.setProperty('--gradient1', this.gradient1);
                document.documentElement.style.setProperty('--gradient2', this.gradient2);
                document.documentElement.style.setProperty('--gradient3', this.gradient3);
                document.documentElement.style.setProperty('--footgradient1', this.footgradient1);
                document.documentElement.style.setProperty('--footgradient2', this.footgradient2);
                document.documentElement.style.setProperty('--resourceLinkColor', this.resourcefontLinkColor ? this.resourcefontLinkColor : "#3254a2");
                document.documentElement.style.setProperty('--resourceLinkRolloverColor', this.resourcefontLinkRolloverColor ? this.resourcefontLinkRolloverColor : "#691c32");
                document.documentElement.style.setProperty('--resourceh1Color', this.resourceh1color ? this.resourceh1color : "#3254a2");
                document.documentElement.style.setProperty('--resourcehorizontalRuleColor', this.resourcehorizontalRule ? this.resourcehorizontalRule : "#3254a2");
                document.documentElement.style.setProperty('--resourcebuttonBackgroundColor', this.resourcebuttonBackgroundColor ? this.resourcebuttonBackgroundColor : "#3254a2");
                document.documentElement.style.setProperty('--resourcebuttonBackgroundRollOverColor', this.resourcebuttonBackgroundRollOverColor ? this.resourcebuttonBackgroundRollOverColor : "#691c32");
                document.documentElement.style.setProperty('--resourcebuttonfontcolor', this.resourcebuttonFontColor ? this.resourcebuttonFontColor : "#ffffff");
                document.documentElement.style.setProperty('--resourcebuttonhoverfontcolor', this.resourcebuttonRolloverFontColor ? this.resourcebuttonRolloverFontColor : "#ffffff");
                document.documentElement.style.setProperty('--footergradientColor', this.resourcebrandFooterBackgroundColor ? this.resourcebrandFooterBackgroundColor : "#3254a2");
                document.documentElement.style.setProperty('--resourceHeaderColor', this.resourceHeaderColor ? this.resourceHeaderColor : "#3254a2");

                document.documentElement.style.setProperty('--headerIndicationHeaderFontSize', this.headerIndicationHeaderFontSize ? this.headerIndicationHeaderFontSize : "19px");
                document.documentElement.style.setProperty('--headerIndicationCopyFontSize', this.headerIndicationCopyFontSize ? this.headerIndicationCopyFontSize : "18px");
                document.documentElement.style.setProperty('--headerIndicationFontColor', this.headerIndicationFontColor ? this.headerIndicationFontColor : "#ffffff");
                document.documentElement.style.setProperty('--isiHeadersFontSize', this.isiHeadersFontSize ? this.isiHeadersFontSize : "1 REM");
                document.documentElement.style.setProperty('--isiHeadersColors', this.isiHeadersColors ? this.isiHeadersColors : "#0072ce");


                //Font Size

                document.documentElement.style.setProperty('--isiTextFontSize', this.isiTextFontSize ? this.isiTextFontSize : "1 REM");
                document.documentElement.style.setProperty('--bodyTextFontSize', this.bodyTextFontSize ? this.bodyTextFontSize : "1 REM");

                //Font Weight
                document.documentElement.style.setProperty('--bodyTextFontWeight', this.bodyTextFontWeight ? this.bodyTextFontWeight : "400");
                document.documentElement.style.setProperty('--isiTextFontWeight', this.isiTextFontWeight ? this.isiTextFontWeight : "400");

                //Font Height
                document.documentElement.style.setProperty('--bodyTextLineHeight', this.bodyTextLineHeight ? this.bodyTextLineHeight : "1.25 REM");
                document.documentElement.style.setProperty('--isiTextLineHeight', this.isiTextLineHeight ? this.isiTextLineHeight : "1.25 REM");


                const gradientColors = [this.gradient1, this.gradient2, this.gradient3];
                const gradientString = `linear-gradient(to right, ${gradientColors.join(", ")})`;
                document.documentElement.style.setProperty('--headergradientColor', "#b5b3b4");
                document.getElementById("headgradient").style.background = gradientString;

                //const footgradientColors = [this.footgradient1, this.footgradient2];
                // const footgradientString = `linear-gradient(to right, ${footgradientColors.join(", ")})`;
                document.getElementById("footgradient").style.background = this.resourcebrandFooterBackgroundColor;
                //document.documentElement.style.setProperty('--headergradientColor', this.resourceprimarycolor);

            })
        } else { console.log("branding not exists") }
        //     })
        // })

    }



    getdrugrelatedbutton() {
        // if (this.resourceres.fields.hasOwnProperty("branddiseaseResources")) {
        //     this.branddiseaseResources_id = this.resourceres.fields.branddiseaseResources.sys.id;
        //     this.contentfulservice.getdata(this.branddiseaseResources_id).subscribe(res => {
        if (this.brandresourceres.fields.hasOwnProperty("branddiseaseHeader")) {
            this.brandheader_id = this.brandresourceres.fields.branddiseaseHeader.sys.id;
            // console.log(this.brandheader_id)
            this.contentfulservice.getdata(this.brandheader_id).subscribe(res => {
                if (res.fields.hasOwnProperty("multiDiseaseQsaLinkstabsWrapper")) {
                    let a = res.fields.multiDiseaseQsaLinkstabsWrapper.sys.id;
                    this.contentfulservice.getdata(a).subscribe(res => {
                        this.realtedbuttonids = res.fields.linkstabsbuttons
                        //console.log(this.realtedbuttonids)
                        let buttonids = this.realtedbuttonids.map(id => id.sys.id)
                        //console.log(buttonids.length)
                        let responseDataArray = [];
                        const requests$ = [];

                        let i;

                        for (let i = 0; i < buttonids.length; i++) {
                            const value = buttonids[i];
                            const urlWithQuery = `${value}`;
                            //console.log(urlWithQuery)
                            if (urlWithQuery !== undefined) {
                                requests$.push(from(this.contentfulservice.getdata(urlWithQuery)));
                            }
                        }

                        zip(...requests$).pipe(
                            concatMap(responses => {
                                responseDataArray = [...responseDataArray, ...responses];
                                if (responseDataArray.length === buttonids.length) {
                                    //console.log(responseDataArray)
                                    this.relatedbuttonarray = responseDataArray;
                                }
                                return [];
                            })
                        ).subscribe();

                        /*for (i = 0; i < buttonids.length; i++) {
                           // alert(i)
                            const value = buttonids[i];
                            const urlWithQuery = `${value}`;
                            console.log(urlWithQuery)
                            if (urlWithQuery !== undefined) {
                               // alert(urlWithQuery)
                                this.contentfulservice.getdata(urlWithQuery).subscribe(res => {
                                    console.log(res)
                                    responseDataArray.push(res);
                                    if (responseDataArray.length === buttonids.length) {
                                        console.log(responseDataArray)
                                        this.relatedbuttonarray = responseDataArray
                                    }
                                })
                            }
                        }*/

                    })
                }
            })
        }
        //     })
        // }
    }


    /*checkUrls(urls: string[]) {
        urls.forEach(url => {
          if (url.endsWith('.pdf')) {
            this.convertedUrls.push(url);
          } else {
            this.convertHtmlToPdf(url);
          }
        });
      }
    
      convertHtmlToPdf(url: string) {
        this.http.get(url, { responseType: 'text' }).subscribe((html: string) => {
          const docDefinition = htmlToPdfmake(html);
          const pdfDocGenerator = pdfMake.createPdf(docDefinition);
          pdfDocGenerator.getDataUrl((dataUrl: string) => {
            this.convertedUrls.push(dataUrl);
          });
        });
      }
    
      printUrls() {
        console.log(this.convertedUrls);
      }*/

    getValidPdfUrls(urls: string[]): string[] {
        const validPdfUrls: string[] = [];

        urls.forEach((url) => {
            if (url.toLowerCase().endsWith('.pdf')) {
                validPdfUrls.push(url);
            } else {
                htmlToPdfmake(url, (pdfDoc) => {
                    const blob = new Blob([pdfDoc], { type: 'application/pdf' });
                    const convertedUrl = URL.createObjectURL(blob);
                    validPdfUrls.push(convertedUrl);
                });
            }
        });

        return validPdfUrls;
    }

    gettextbanner() {

    }


}