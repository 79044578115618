import { Component, OnInit, Output, ViewChild,  } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MatSidenavContent } from '@angular/material';
import { Key } from 'protractor';
import { Meta } from '@angular/platform-browser';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @ViewChild('sidenavContent', {static:false}) sidenavContentScrollable: MatSidenavContent;

  panelOpenState: boolean = false;
  panelOpenState1: boolean = false;
  panelOpenState2: boolean = false;
  id: string = "";

  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<any[]>;
  form: FormGroup;
  submitted = false;
  mainText: Observable<[]>;
  subheadtext: Observable<[]>;
  subheadpara: Observable<[]>;
  subheadtext1: Observable<[]>;
  subheadpara1: Observable<[]>;
  subheadtext2: Observable<[]>;
  subheadpara2: Observable<[]>;
  getImg: string;
  siteKey = environment.siteKey
  formTitle: any;
  mainContent: any;
  reqId : any
  medName : string[] = [];
  hi:any

  goThrough : string[] = [];
  versionone_med: void;
  versionone_medname: any;
  optionchoosed: boolean = false;
  drugdata: any;
  drugcontentfulid: any;
  seotitle: any;
  seodes: any;
  hidePagesFromSearchEnginesNoindex: any;
  excluedLinksFromSearchRankingsNofollow: any;
  pagekeywords: any;

  readonly SITE_KEY = environment.siteKey;
  action = "register";

  token: string = undefined;



  constructor( private contentfulservice:ContentfulService,private http :HttpClient,
    private formBuilder: FormBuilder, private router: Router, private titleService : Title, private metaservice: Meta, private reCaptchaV3Service: ReCaptchaV3Service) { }
    
     ngOnInit():void {

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Reload the window
          window.location.reload();
        }
      });

      document.getElementById('div2').style.display = 'none';
      document.getElementById('button1').style.display = 'block';
      document.getElementById('button2').style.display = 'none';

      this.contentfulservice.getVersiononeMedicines().subscribe(res =>{
        this.drugdata = res;
        //console.log(this.drugdata)
         this.versionone_med = res.results.map(item => {
         // console.log(item.contentfulId);
          return item.name;
         });
      })

      

      this.contentfulservice.getdata('4L2umR10iUjZAWQbZPxHSI').subscribe(res =>{
        this.titleService.setTitle(res.fields.seoTitle)
        this.seotitle = res.fields.seoTitle;
        this.seodes = res.fields.seoDescription;
        this.hidePagesFromSearchEnginesNoindex = res.fields.hidePagesFromSearchEnginesNoindex;
        this.excluedLinksFromSearchRankingsNofollow = res.fields.excluedLinksFromSearchRankingsNofollow;
        this.pagekeywords = res.fields.keywords;
        //console.log(this.seotitle, this.seodes, this.hidePagesFromSearchEnginesNoindex, this.excluedLinksFromSearchRankingsNofollow, this.pagekeywords )

        this.metaservice.addTag( { name:'description',content: this.seodes});
        this.metaservice.addTag( { name:'application-name',content: this.seotitle});
        this.metaservice.addTag( { name:'keywords',content: this.pagekeywords});
        this.metaservice.addTag( { name:'noindex',content: this.hidePagesFromSearchEnginesNoindex});
        this.metaservice.addTag( { name:'nofollow',content:  this.excluedLinksFromSearchRankingsNofollow});
       // console.log(res)
      })

      

      this.form = this.formBuilder.group({
        goThrough: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
        //recaptcha: ['', Validators.required],
        zipCode:['']
      });

      

      /*this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );*/

      this.getMaintext()
      this.getDropdown()
      this.getDropdown1()
      this.getDropdown2()
      this.getvalue();
      this.getPrivacyContent();
      this.homepageMain()
      this.getGraphicImg();



      }

      private reloadWindow(): void {
        window.location.reload();
      }

  onSubmit(id) {
    this.submitted = true ;
    if (!this.form.invalid){

      

    this.contentfulservice.getMedName(this.goThrough).subscribe(res =>{
      this.medName = res.drugNames.map(item => {
        return item.drugName;
       });
      console.log(this.medName);
      });

      this.contentfulservice.getDetails().subscribe((res)=>{
        this.reqId =  res._id
        //console.log(res);
        
      this.router.navigateByUrl('resource/'+ `${this.medName}/` + `${this.reqId}`)
      })

    }

    //function filterItems(arr, query) {
    //  return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
    //}

    //this.versionone_medname = filterItems( this.versionone_med, this.goThrough)
   // console.log(this.versionone_medname); 
    /* if (!this.form.invalid) {
      //console.log(this.goThrough)
      this.contentfulservice.getMedName(this.goThrough).subscribe(res =>{
        this.medName = res.drugNames.map(item => {
          return item.drugName;
         });
        console.log(this.medName);

        if (this.versionone_medname.length == 0){
          //alert("Mismatch!")
          document.getElementById('div2').style.display = 'block';
          
          this.form.get('zipCode').setValidators(Validators.required);
          //this.router.navigateByUrl('pharmacy')
          if (!this.form.invalid){
            
          this.router.navigateByUrl('pharmacy')
          }
          
        }
        else{

        this.router.navigateByUrl('resource/hizentra/' + `${this.reqId}`)
        //this.router.navigateByUrl('resource/'+ `${this.medName}/` + `${this.reqId}`)
        }
      })
    }else{
      
    }*/
    /* if(this.versionone_medname.length !== 0){
      if (!this.form.invalid){
        this.router.navigateByUrl('resource/'+ `${this.medName}/` + `${this.reqId}`)
      }
    }
    else{
      document.getElementById('div2').style.display = 'block';
      document.getElementById('button1').style.display = 'none';
      document.getElementById('button2').style.display = 'block';
      this.form.get('zipCode').setValidators([Validators.required]);
    }*/
  }

  onSubmit2(id){
    this.submitted = true ;
    if (!this.form.invalid){
    
      let dname = this.form.get('goThrough').value;
      let zcode = this.form.get('zipCode').value;
      //console.log(dname, zcode)
      let value = [dname, zcode]
      let v = {
        drug: this.form.get('goThrough').value,
        zip: this.form.get('zipCode').value
      }
      let url = 'https://api.qsasupport.com/v1/resources/pharma';
      this.http.post(url, v).subscribe(res => {
          console.log(res);
      })
      this.router.navigate(
        ['pharmacy/'],
        { queryParams: { drugname: dname, 'zipcode': zcode } }
      );
    }
  }

  get f() { return this.form.controls; }

  privacyContent:any;
  getPrivacyContent(){  
    this.contentfulservice.getdata('5QKXAS9MlInWFsh7BQrZlW').subscribe(res =>{
      this.privacyContent = res.fields.label;
    })
  }

  getMaintext(){
    this.contentfulservice.getdata('2vtcXRzdXiQE8LtCHyIuED').subscribe(res =>{
      this.mainText = res.fields.heading
    })
  }

  getDropdown(){
      this.contentfulservice.getdata('2plT3YBUYXCoaxgJWStdEG').subscribe(res =>{
        this.subheadtext = res.fields.subHead
        this.subheadpara = res.fields.copy.content[0].content[0].value
      })
  }

  getDropdown1(){
    this.contentfulservice.getdata('6gQ07sz92tuVybdyp6vX7n').subscribe(res =>{
      this.subheadtext1 = res.fields.subHead
      this.subheadpara1 = res.fields.copy.content[0].content[0].value
    })
  }

  getDropdown2(){
    this.contentfulservice.getdata('4VtviL3xH4WtD9WkJZ1njl').subscribe(res =>{
      this.subheadtext2 = res.fields.subHead
      this.subheadpara2 = res.fields.copy.content[0].content[0].value
    })
  }

  getvalue(){
    this.contentfulservice.getdata('6MidkYipH3rFPJpvFwWH0P').subscribe(res=>{
      this.formTitle =  res.fields.heading
    })
  }
  
  getGraphicImg(){
    this.contentfulservice.getAssets('/3yVCaeMAPGtnG6VU2kt7vC/').subscribe(res=>{
      this.getImg = res.fields.file.url
    })
  }

  public options2:any = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`,
      [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
    }
  }


  _returnHtmlFromRichText(richText) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
        return '<p>Loading</p>';
    } else {
        return documentToHtmlString(richText, this.options2);
    }
}

  homepageMain(){
    this.contentfulservice.getdata('2vtcXRzdXiQE8LtCHyIuED').subscribe(res =>{
      this.mainContent = res.fields.copyBlock
    })
  }

  /*inputHandle(event) {
    var option = event.target.value;
  
    if (option.length >= 3) {
      //console.log(option)
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        debounceTime(400),
        filter( (value: string) => value.trim().length > 1) ,
        startWith(''),
        map((value: string) =>  this._filter(value)
        )
      );
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.contentfulservice.getMedName(this.goThrough).subscribe(res =>{
      this.medName = res.drugNames.map(item => {
        return item.drugName;
       });
    })
    this.options = this.medName;
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }*/

  inputHandle(event) {
    const option = event.target.value;
    //console.log(option)
    if (option.length >= 3) {
      this.contentfulservice.getMedName(option).subscribe(res => {
        this.medName = res.drugNames.map(item => item.drugName);
        console.log(this.medName)
        this.filteredOptions = of(this.medName.filter(med => med.toLowerCase().includes(option.toLowerCase())));
      });
    } else {
      this.filteredOptions = of([]);
    }
  }
  
  private _filter(value: string): void {
    if (value.length < 3) {
      return;
    }
  
    const filterValue = value.toLowerCase();
    this.contentfulservice.getMedName(this.goThrough).subscribe(res => {
      this.medName = res.drugNames.map(item => {
        return item.drugName;
      });
      this.options = this.medName.filter(option => option.toLowerCase().includes(filterValue));
    });
  }
  
  
  

  onSelectionChanged(event: any) {

    this.optionchoosed = true;

    function filterItems(arr, query) {
      return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
    }

    this.versionone_medname = filterItems( this.versionone_med, this.form.get('goThrough').value);
    console.log(this.versionone_medname.length);

    if(this.versionone_medname.length === 0){
      //alert("true")
      document.getElementById('div2').style.display = 'block';
      document.getElementById('button1').style.display = 'none';
      document.getElementById('button2').style.display = 'block';
      this.form.get('zipCode').setValidators([Validators.required]);
      this.form.get('zipCode').updateValueAndValidity();
    }else{
      document.getElementById('div2').style.display = 'none';
      document.getElementById('button1').style.display = 'block';
      document.getElementById('button2').style.display = 'none';
      this.form.get('zipCode').clearValidators();
      this.form.get('zipCode').updateValueAndValidity();
    }
    
    var result2 = this.drugdata.results.find(item => item.name === this.versionone_medname[0]);
    if(result2 !== undefined){
    console.log(result2.contentfulId)
    this.drugcontentfulid = result2.contentfulId;
    this.contentfulservice.setTest(this.drugcontentfulid);
    }else(
      console.log("resource not found for drug")
    )
    //this.contentfulservice.resid_Visibility(this.drugcontentfulid)

    console.warn("Token");

      this.reCaptchaV3Service.execute(
        this.SITE_KEY,
        this.action,
        token => {
          console.log(`%c This is your token :${token}`, "color: blue;", token);
          this.token = token;
        },
        {
          useGlobalDomain: false // optional
        }
      );
     
  }

  omit_special_char(event)
{   
  // var k;  
  // k = event.charCode;  //         k = event.keyCode;  (Both can be used)
  // return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || ); 
   const pattern = /[0-9\+\-\ ]/;
   let inputChar = String.fromCharCode(event.key);

   if (!pattern.test(inputChar)) {
     // invalid character, prevent input
     event.preventDefault();
   }
}

} 
